// Get everything reasonable that we can from www
@import 'shared/variables';

//bootstrap override
$font-family-monospace: "Courier New", monospace, serif;
$font-family-base: "Source Sans 3", Arial, sans-serif;
$font-family-highlight: "Source Sans 3", Arial, sans-serif;
$headings-font-family: $font-family-highlight;
$nav-font-family: $font-family-highlight;
$footer-font-family: $font-family-highlight;

// Brand Colors
$sapphire: #261F63;
$plum: #7F2854;
$plum-dark: #611D3F;
$white: #FFFFFF;
$black: #000000;
$medium-gray: #898A8D;
$magenta: #EC0889;
$cyan: #00AAEF;
$cyan-dark: #0069A7;
$tangerine: #F05918;
$lime: #DAED43;
$tangerine-dark: #A83E11;

$accent-footer-hero: $tangerine;
$text-nav-hover: $tangerine;
$text-nav-current-page: $tangerine;
$text-current: $tangerine;
$btn-basic-rest: $white;
$btn-basic: $white;
$link-color: $tangerine;
$btn-basic-hover: $tangerine-dark;
$link-hover-color: $tangerine-dark;
$extrahop-logo-url-white: 'https://cloud-assets.extrahop.com/logos/extrahop/white.svg';
$extrahop-logo-icon-url: 'https://cloud-assets.extrahop.com/logos/extrahop/white.svg';


$code-color: $text-body;
$code-bg: transparent;

$extrahop-logo-url: 'https://assets.extrahop.com/images/logos/extrahop-logo-docs.png';

$fixed-number-nav-items: 6;
