html { height: 100%; }

$heading-font-weight: $base-font-weight;
$subheading-font-weight: 400;
////
// Basic body blocks
////
body {
    height: calc(100% - #{$nav-height-prime});

    margin-top: $nav-height-prime;
    position: relative;
    font-weight: $base-font-weight;

    background-color: $bg-base;

    overflow-x: hidden;

    &.window-exclusive-showing {
        overflow: hidden;
    }
}

main {
    margin-top: $body-spacing;
}

////
// Text
////
h1, h2, h3, h4, h5, h6 {
    margin-top: $size-small;
    margin-bottom: ($line-height-computed / 3);

    font-family: $headings-font-family;

    .fa-stack {
        margin-top: -5px;

        color: $accent-dark;
    }
}

h1 {
    color: $heading-prime;
}

h2, h3 {
    color: $heading-sub;

    a {
        font-weight: $heading-font-weight;
    }
}

h1, h2, h3 {
    font-weight: $heading-font-weight;
}

h4, h5, h6 {
    color: $heading-body;
    font-weight: $base-font-weight;
}

nav {
    font-weight: $nav-font-weight;
}

p {
    margin-bottom: $line-height-computed;
}

//Font-weight normal because standard font-weight is light
strong, b {
    font-weight: normal;
}

.embiggened {
    font-size: $font-size-large;

    p, li {
        font-size: $font-size-large;
    }
}

.smallified {
    font-size: $font-size-small;

    p, li {
        font-size: $font-size-small;
    }
}

.light-weight, .light-weight a {
    font-weight: 300;
}

.heavy-weight, .heavy-weight a {
    font-weight: 600;
}

.no-weight {
    font-weight: normal;
}

.legalese {
    font-size: $font-size-mini;
    color: $gray-dark;
}

.footnote {
    font-size: $font-size-small;

    li {
        margin-bottom: $size-small;
    }
}

.accent-dark {
    color: $accent-dark;
}

.text-top {
    vertical-align: text-top;
}

////
// Links
////
a {
    color: $link-color;

    &:hover, &:focus {
        text-decoration: none;
        color: $link-hover-color;
        transition: $transition-speed-link;
    }
}

// anchor point class is used to ensure that anchor targets
// do not get hidden below our fixed header
.anchor-point {
    display: block;
    border-top: $anchor-point-offset solid transparent;
    margin-top: -$anchor-point-offset;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
}

////
// Buffers, padding/margin, line height
////
.buffer-small {
    height: $size-small;
}

.buffer-med {
    height: $size-medium;
}

.buffer-large {
    height: $size-large;
}

.m-top-none {
    margin-top: 0px;
}

.m-top-5 {
    margin-top: 5px;
}

.m-top-10 {
    margin-top: 10px;
}

.m-top-small {
    margin-top: $size-small;
}

.m-top-med {
    margin-top: $size-medium;
}

.m-top-large {
    margin-top: $size-large;
}

.m-bottom-none {
    margin-bottom: 0px;

    &.grid.row {
        @media (min-width: $size-flop) {
            margin-bottom: -$size-medium;
        }

        .item {
            margin-bottom: 0px;
        }
    }
}

.m-bottom-small {
    margin-bottom: $size-small;
}

.m-bottom-large {
    margin-bottom: $size-large;
}

.m-vert-none {
    margin-top: 0px;
    margin-bottom: 0px;
}

.m-vert-small {
    margin-top: $size-small;
    margin-bottom: $size-small;
}

.m-vert-med {
    margin-top: $size-medium;
    margin-bottom: $size-medium;
}

.m-small {
    margin: $size-small;
}

.p-top-small {
    padding-top: $size-small;
}

.p-top-med {
    padding-top: $size-medium;
}

.p-top-large {
    padding-top: $size-large;
}

.p-bottom-large {
    padding-bottom: $size-large;
}

.p-vert-none {
    padding-top: 0px;
    padding-bottom: 0px;
}

.p-vert-small, tr.p-vert-small td,
.table > tbody.p-vert-small > tr > td {
    padding-top: $size-small;
    padding-bottom: $size-small;
}

.p-small {
    padding: $size-small;
}

.p-med {
    padding: $size-medium;
}

.p-sides-none, .box-standard.p-sides-none {
    padding-left: 0px;
    padding-right: 0px;
}

.p-sides-small {
    padding-left: $size-small;
    padding-right: $size-small;
}

.p-sides-med {
    padding-left: $size-medium;
    padding-right: $size-medium;
}

.m-right-10 {
    margin-right: 10px;
}

.m-left-10 {
    margin-left: 10px;
}

main h2:not(.anchor-point):first-of-type {
    margin-top: 0px;
}

////
// Hard rules
////

hr {
    margin: $size-small 0px;

    &.medium {
        margin: $size-medium 0px;
    }

    &.large {
        margin: $size-large 0px;
    }

    &.arrowed {
        $arrow-size: $size-small;

        &::before, &::after {
            content: "";
            left: 50%;
            border-style: solid;
            border-width: $arrow-size $arrow-size 0;
            display: block;
            width: 0;
            position: relative;
            margin-left: -$arrow-size / 2;
        }

        &::before {
            border-color: $accent-grayscale-light transparent;
        }

        &::after {
            border-color: $bg-base transparent;
            top: -$arrow-size - 1;
        }
    }
}

.skinnyline {
    line-height: 1.1;
}

////
// General utility
////
.nowrap {
    white-space: nowrap;
}

.full-width, .hero > .container > .full-width {
    width: 100%;
}

.interactable {
    cursor: pointer;
}

.threequarter-width {
    width: 75%;
}

.half-width {
    width: 50%;
}

.quarter-width {
    width: 25%;
}

.centered {
    margin: 0px auto;

    &.sprite, .sprite {
        margin: 0px auto;
    }
}

.v-centered {
    @media (min-width: $size-flop-secondary) {
        display: table;

        &.hero {
            width: 100%;

            > .v-centered-inner {
                display: table-cell;
                vertical-align: middle;
            }
        }

        &.row > [class^="col-"] {
            display: table-cell;
            vertical-align: middle;
            float: none;
        }
    }
}

.v-bottomed {
    display: table;

    &.row > [class^="col-"] {
        display: table-cell;
        vertical-align: bottom;
        float: none;
    }
}

.compact {
    padding: 0px;
    margin: 0px;

    &.table {
        margin: 0px;
    }
}

.left {
    float: left;
    clear: left;
    margin: 0 $size-small $size-small 0;
}

.right {
    float: right;
    clear: right;
    margin: 0 0 $size-small $size-small;
}

.suppress-size-flop {
    @media (max-width: $size-flop) {
        display: none;
    }
}

.suppress-size-flop-secondary {
    @media (max-width: $size-flop-secondary) {
        display: none;
    }
}

.target-collapsed, .target-collapsing,
.target-expanded, .target-expanding {
    display: block;
    border-bottom: $border-grayscale-accent;

    &:hover, &:focus {
        border-bottom: $border-grayscale-accent;
        cursor: pointer;
    }

    &::after {
        margin-left: $size-small;
        font-family: "FontAwesome";
    }
}

.target-collapsed, .target-collapsing {
    &::after {
        content: "\f107"; // fa-angle-down
    }
}

.target-expanded, .target-expanding {
    &::after {
        content: "\f106"; // fa-angle-up
    }
}

.collapse, .collapsing {
    border: 1px solid transparent;

    > .table:first-child tr:first-child {
        td {
            border-top-color: transparent;
        }
    }

    > ul:first-child, > ol:first-child {
        margin-top: $size-small;
    }
}

.affix {
    z-index: $z-index-sticky;
    top: $anchor-point-offset;

    // to avoid various overruns, we do not affix at our smallest size
    @media (max-width: $size-flop-secondary) {
        position: relative;
        top: inherit;
    }
}

.affix-top { // extend instead of add above, as there are other styles in bootstrap
    @extend .affix;
}

.image-column {
    overflow: hidden;

    .content {
        overflow: hidden;
    }
}

.debug {
    width: 90%;
    margin: auto;
    font-size: $font-size-small;
}

.grayscale {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.constrained {
    max-width: 100%;
}

@media (min-width: $size-ipad-portrait) {
    .breakout {
        margin-left: -100%;
        margin-right: -100%;
    }
}

@media (max-width: $size-flop-secondary) {
    img.breakout {
        max-width: 100%;
    }
}


.framed {
    border: $border-grayscale-accent;
}

////
// Floods and other colorization behavior
////
.flood-grayscale { background-color: $bg-grayscale; }
.flood-colorized { background-color: $bg-colorized; }
.flood-gray-dark { background-color: $bg-darkest; }

.flood-base {
    background-color: $bg-base;

    color: $text-body;
}

.box-standard {
    background-color: $bg-grayscale;
    padding: $size-medium;
    overflow: hidden;
}

.on-dark, .on-bg-colorized {
    color: $on-dark;

    h1, h2, h3, h4, h5, h6, &.hero {
        color: $on-dark;
    }
}

.on-dark {
    @include link-setup($on-dark, $on-dark);
}

.alert-text {
    color: $link-disruptor-color;
}

.on-bg-colorized {
    background-color: $bg-colorized;

    @include link-setup($link-color-bg-colorized, $link-color-bg-colorized-hover);
}

.bg-info {
    background-color: $bg-colorized-light;

    p:first-child {
        margin-bottom: $line-height-computed / 3;
        padding: 0px $size-small/2;
    }
}

////
// iframe
////
iframe.upload {
    width: 100%;
    height: 300px;
    margin: 0;
    padding: 0;
    border: none
}

iframe + blockquote {
    margin-top: $size-small;
}

////
// Other
////

.navigation {
    .prev::before {
        @extend .decorate-before;
        content: "\f053\00a0"; // chevron-left space
    }

    .next::after {
        @extend .decorate-after;
        content: "\00a0\f054"; // chevron-right space
    }
}

// Bootstrap does cray cray things to navs, undo it
.nav > li {
    a {
        display: initial;
        padding: 0px;

        &:hover, &:focus {
            background-color: transparent;
        }
    }
}

.orange-disruptor {
    background-color: $disruptor-bg-color;
    color: $on-dark;

    h2, h3 {
        color: $on-dark;
    }

    a:not(.effectless):not(.btn-seethrough) {
        border-color: $on-dark;

        &:hover, &:focus {
            border-color: transparent;
        }
    }

    .btn-seethrough {
        color: $link-disruptor-color;

        &:hover, &:focus {
            color: $on-dark;
        }
    }
}

.decorate-before {
    font-family: FontAwesome;
    margin-left: -$size-small;
}

.decorate-after {
    font-family: FontAwesome;
    margin-right: -$size-small;
}

a.secured {
    &::after {
        @extend .decorate-after;
        content: '\00a0\f023'; // lock icon (login required)
    }
}

.ajax-spinner {
    position: absolute;
    z-index: 999;
    display: none;
    padding: 5px;
    font-size: inherit;
    line-height: 11px;
    background-color: #ccc;
    border-radius: 4px;

    img {
        width: inherit;
        height: inherit;
        margin: 0;
    }
}
