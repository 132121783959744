////
// Assets Host
////
$assets-host: "https://assets.extrahop.com";
// Helper function to build a variable string,
// all other functions should exist inside mixins
@function img-asset-url($path) {
    @return #{$assets-host + "/images" + $path};
}

////
// Color definitions
//
// Colors sorted approximately lightest -> darkest order within their block
// Avoid using colors directly in favor of semantic names defined below
////

// Grayscale
$white: #FFFFFF;
$gray-pale: #EEEEEE;
$gray-lighter: #E5E5E5;
$gray-light: #D8D8D8;
$gray-med: #C8C8C8;
$gray-dark: #727272;
$gray-darkest: #424242;
$near-black: #2D2D2D;
$black: #000000;

// Reds
$state-danger-text: #F05252; //Bootstrap Override

// Oranges
$orange: #F05A28;

// Yellows
$state-warning-text: #8a6d3b; //Bootstrap override

// Greens
$green-bright: #8AC040;
$green-dark: #12996E;
$state-success-text: #3C763D; //Bootstrap override

// Blues
$blue: #1A5A75;
$blue-pale: #C0E1E9; // also is the bootstrap state-info-bg color
$teal: #29A3A0;
$teal-dark: #189390;
$blue-dark: #126B95;

////
// Text
//
// Many of these are overrules to bootstrap declarations
////

$font-family-base: Lato, Arial, sans-serif;
$font-family-highlight: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
$nav-font-weight: 400;
$base-font-weight: 300;
$hero-font-weight: $base-font-weight;

$font-size-base: 16px;
$font-size-h1: floor(($font-size-base * 3)); // ~45px
$font-size-h2: floor(($font-size-base * 2)); // ~32px
$font-size-h3: floor(($font-size-base * 1.6)); // ~26px
$font-size-h4: floor(($font-size-base * 1.25)); // ~20px
$font-size-h5: $font-size-base; // h5 are bold body copy

$font-size-large: $font-size-h4;
$font-size-med: floor(($font-size-base * 0.9)); // ~14px
$font-size-small: floor(($font-size-base * 0.8)); // ~12px
$font-size-mini: floor(($font-size-base * 0.7)); // ~10px

$nav-font-size: $font-size-med;

$blockquote-font-size: $font-size-base;

$line-height-base: 1.6;
$headings-line-height: 1.3;
$line-height-large: 2;

$delim-decoration: " | ";

////
// Forms and buttons (many are bootstrap overrides)
////
$input-border-focus: $blue-pale; //Bootstrap override

$btn-border-radius-base: 0px; // bootstrap override
$input-border-radius: 0px; // bootstrap override

////
// Transitions
////

$transition-speed-link: .3s;
$transition-speed-search: .4s;

////
// Sizes and heights
////
$size-small: 15px;
$size-medium: 30px;
$size-large: 60px;

$size-flop: 992px; // maps to bootstrap screen-md
$size-flop-secondary: 768px; // maps to bootstrap screen-sm
$size-flop-minimum: 320px;

$size-ipad-portrait: 768px;
$size-ipad-landscape: 1024px;
$size-iphone-large-portrait: 414px;
$size-iphone-large-landscape: $size-flop-secondary;
$size-iphone-small-portrait: $size-flop-minimum;
$size-iphone-small-landscape: 667px;


$footer-height: $size-large;
$footer-spacing: $size-large;

$body-spacing: $size-large;

// Override bootstrap
$grid-gutter-width: $size-medium;

$z-index-base: 1;
$z-index-sticky: 5;
$z-index-overlay-panel: 10;
$z-index-form-errors: 10;
$z-index-chrome: 15;

$nav-height-prime: $size-medium * 2;

$anchor-point-offset: $nav-height-prime + $size-small;

// this is currently based on the tallest known hero height which is the blogs
$height-hero: 275px;
$height-hero-short: 150px;
$height-hero-minimal: $size-medium;
$height-hero-full: 683px - $nav-height-prime;

////
// Semantic assignments
////
$bg-base: $white;
$dark: $black;
$on-dark: $white;
$on-dark-muted: $blue-pale;

$inactive: $bg-base;

$accent-light: $blue-pale;
$accent-dark: $blue;
$accent-darkest: $blue;
$accent-contrast: $green-dark;
$accent-grayscale-pale: $gray-pale;
$accent-grayscale-light: $gray-light;
$accent-grayscale-dark: $gray-dark;
$accent-footer-hero: $teal;
$accent-footer-hero-text: $white;
$twitter-accent: $blue;

$heading-prime: $gray-darkest;
$heading-sub: $gray-darkest;
$heading-body: $gray-darkest;
$text-body: $gray-darkest;
$text-quote-mark: $blue-pale;
$text-quote: $gray-dark;
$text-color: $text-body; //bootstrap override

$headings-font-family: $font-family-highlight;

$text-nav: $white;
$text-nav-hover: $teal;
$text-nav-current-page: $teal;

$nav-font-family: $font-family-highlight;
$nav-bg-color: $near-black;

$text-cta: $white;
$text-cta-inverted: $blue;
$text-current: $teal;

$btn-basic-rest: $teal;
$btn-basic-hover: $teal-dark;

$btn-font-family: $font-family-highlight;
$btn-font-size: $font-size-small;

$link-color: $teal;
$link-hover-color: $blue;
$link-color-bg-colorized: $white;
$link-color-bg-colorized-hover: $white;
$link-color-standout: $link-color;
$link-color-standout-hover: $link-hover-color;
$link-disruptor-color: $orange;

$hr-border: $accent-grayscale-light;

$footer-font-family: $font-family-highlight;

$extrahop-logo-url: img-asset-url('/logos/extrahop-logo.svg');
$extrahop-logo-url-white: img-asset-url('/logos/extrahop-logo-white.svg');
$extrahop-logo-url-black: img-asset-url('/logos/extrahop-logo-black.svg');
$extrahop-logo-icon-url: img-asset-url('/icons/extrahop-icon-55-by-55.png');

$fixed-width-utils: 235px;
$fixed-width-brand-image: 130px;
$fixed-height-brand-image: 30px;
$fixed-width-brand: $fixed-width-brand-image + 25px;
$fixed-height-brand-icon: 30px;
$fixed-width-brand-icon: 30px;
$fixed-number-nav-items: 5;

$bg-colorized-light: $blue-pale;
$bg-colorized: $blue;
$bg-grayscale: $gray-pale;
$bg-gray-darker: $gray-lighter;
$bg-dark: $gray-dark;
$bg-darkest: $gray-darkest;
$bg-gradient: linear-gradient(to bottom, $accent-darkest 0, $accent-dark 75%);
$bg-pattern-image: img-asset-url('/banners/hero-background-pattern.png');
$bg-header: $bg-grayscale;
$bg-quote: $bg-base;
$disruptor-bg-color: $orange;

$blockquote-border-color: $accent-grayscale-light; // Overruling bootstrap
$border-curve: $size-small/3;
$border-grayscale-accent-color: $accent-grayscale-light;
$border-gray-dark: $gray-med;
$border-grayscale-accent: 1px solid $border-grayscale-accent-color;
$border-color-quote: $gray-dark;

$hero-constrained: 80%;

