@mixin display-flex() {
    display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;      /* TWEENER - IE 10 */
    display: -webkit-flex;     /* NEW - Chrome */
    display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

@mixin flex-direction-row() {
    -webkit-box-orient: horizontal;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
}

@mixin flex-direction-column() {
    -webkit-box-orient: vertical;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
}

@mixin flex($value) {
    -webkit-box-flex: $value;      /* OLD - iOS 6-, Safari 3.1-6 */
    -moz-box-flex: $value;         /* OLD - Firefox 19- */
    -ms-flex: $value;              /* IE 10 */
    -webkit-flex: $value;          /* Chrome */
    flex: $value;                  /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

@mixin border-radius($radius) {
    @include experimental(border-radius, $radius)
}

@mixin box-shadow($shadown) {
    @include experimental(box-shadow, $shadown)
}

@mixin experimental($property, $value) {
    -webkit-#{$property}: $value;
    -moz-#{$property}: $value;
    -ms-#{$property}: $value;
    -o-#{$property}: $value;
    #{$property}: $value;
}

@mixin disable-bs-container-margin-tweak() {
    .container::before, .container::after {
        display: block;
    }
}

@function grayscale($value) {
    @return #{ "grayscale(" + $value + ")" };
}

@mixin link-setup($rest-color, $hover-color) {
    a {
        color: $rest-color;
    }

    a:hover, a:focus {
        color: $hover-color;
        border-color: $hover-color;
    }
}

@mixin link-setup-nav($rest-color, $hover-color) {
    @include link-setup($rest-color, $hover-color);

    a:hover, a:focus {
        border: none;
    }
}

@mixin link-setup-subnav($rest-color, $hover-color) {
    @include link-setup-nav($rest-color, $hover-color);

    transition: border $transition-speed-link;
}

@mixin backgroundify($options) {
/*  All options are optional
    bg-color, gradient, and pattern have defaults
    image defaults to null
    gradient and pattern can be nullified via none

    $options: (
        'bg-color': replacement background color to use
        'gradient': replacement gradient to use or "none" (will nullify)
        'image': image to overlay over background pattern + gradient
        'pattern': replacement pattern image to use or "none" (will nullify)
    );
*/

    $final-bg-color: $bg-colorized;
    $final-gradient: $bg-gradient;
    $final-image: null;
    $final-pattern: $bg-pattern-image;

    @if map-get($options, bg-color) { $final-bg-color: map-get($options, bg-color); }

    @if map-get($options, gradient) {
        $final-gradient: map-get($options, gradient);
        @if $final-gradient == 'none' { $final-gradient: null; }
    }

    @if map-get($options, image) { $final-image: map-get($options, image); }

    @if map-get($options, pattern) {
        $final-pattern: map-get($options, pattern);
        @if $final-pattern == 'none' { $final-pattern: null; }
    }

    background-color: $final-bg-color;
    background-image: url($final-image), url($final-pattern), $final-gradient;
    background-repeat: repeat-y, repeat;
    background-position: center top;
}
