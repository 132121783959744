.markdown-content {
    ul:not(.list-unstyled) {
        margin-left: $size-small;
    }

    ol:not(.list-unstyled) {
        margin-left: $size-small + 5;
    }

    ul {
        li {
            list-style: disc;
        }

        &.list-unstyled li {
            list-style: none;
        }
    }

    .list-unstyled ul {
        margin-left: 0px;

        li {
            list-style: none;
        }
    }

    img {
        max-width: 100%;
    }
}
