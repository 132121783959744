@mixin indentify($depth) {
    // API_Name, Note, and example do not get indented originally
    // 2+ get successively indented by base
    //
    // API_Description gets an original base indent
    // 2+ gets additional successive indentation by base
    //
    // Lists need a reset indent
    // Ones directly after an .API_Description* match that indent

    $indent-base: $size-small * 1.5;
    $indent-list: $size-small + 5;

    ul, ol {
        margin-left: $indent-list;
    }

    .API_Description {
        margin-left: $indent-base;

        +ul, +ol {
            margin-left: $indent-base + $indent-list;
        }

        + .NavigationAid {
            margin-left: $indent-base;
        }
    }

    @for $curDepth from 2 through $depth {
        $oneUp: $curDepth - 1;
        $curIndent: $indent-base * $oneUp;

        .API_Name#{$curDepth}, .Note#{$curDepth}, .note#{$curDepth}, .example#{$curDepth} {
            margin-left: $curIndent;
        }

        @if $oneUp > 1 {
            .API_Description#{$oneUp} {
                margin-left: $curIndent;

                +ul, +ol {
                    margin-left: $curIndent + $indent-list;
                }

                + .NavigationAid {
                    margin-left: $curIndent;
                }
            }
        }
    }
}

.header-logo {
    margin-top: 9px;
    display: block;
}

.demo-btn {
    background-color: $plum;
    border-color: $plum;
    &:hover, &:focus {
        background-color: $plum-dark;
    }
}

.demo-btn {
    background-color: $plum;
}

.documentation main {
    @include indentify(5);

    // no need to display lock icon if someone has already logged in
    // to access a restricted doc in the first place
    &.restricted .pdf a::after {
        display: none;
    }

    pre, code, kbd, samp:not(.codeph), [class~="code"], [class~="Code"] {
        @extend code;
        font-family: $font-family-monospace;
        padding: $size-small;
        color: $text-quote;
    }

    kbd.userinput {
        padding: 0;
    }

    .filepath {
        font-family: $font-family-monospace;
    }

    h1 {
        font-size: 32px;
    }
    h2 {
        font-size: 26px;
    }
    h3 {
        font-size: 20px;
    }
    h4{
        font-size: 18px;
    }
    h5{
        font-size: 16px;
    }
    h6{
        font-size: 14px;
    }

    h2, .topic h2:first-of-type, h3, h4, h5, h6, h7, a[name] {
        @extend .anchor-point;

        &[madcap\:generatedbookmark="Index"]::before {
            display: inline;
        }
    }

    h2.title:first-of-type {
        margin-top: -$anchor-point-offset;
    }

    &.release-notes {
        h2 {
            font-size: $font-size-h3;
        }

        h3 {
            font-size: $font-size-h4;
        }
    }

    [class^="API_Name"] {
        margin-top: $size-small;
        margin-bottom: 0px;
    }

    [class^="Note"], [class^="note"],
    [class^="example"], [class^="tip"],
    [class^="important"], [class^="video"],
    [class^="warning"] {
        padding: $size-small;
        border-radius: $border-curve;
        background-color: $bg-grayscale;
    }

    [class^="example"] {
        background-color: $bg-colorized-light;
        font-family: $font-family-monospace;
        font-size: $font-size-small;
        white-space: pre;
        overflow-wrap: break-word;
    }

    .codeblock {
        background-color: $medium-gray;
        color: $white;
        padding: 20px 15px;
        &.anchor-point {
            border-top: 1px;
        }
    }

    .code-container{
        position: relative
    }

    .copy-button{
         @extend .btn-basic;
         position: absolute;
         top:0;
         right:0;
         margin-top: 5px;
         margin-right: 5px;
         font-weight: 600;
         font-size: 12px;
         opacity: .5;

         &:hover{
             opacity: 1;
         }
    }

    .codeblock strong {
        font-weight: bold;
    }

    .text-highlight {
        color: $lime;
    }

    .notetitle, .uicontrol, .keyword, .importanttitle, .tiptitle, .videotitle, .warningtitle {
        font-weight: 400;
        min-width: 105px;
    }

    .keyword.wintitle {
        font-weight: $base-font-weight;
    }

    .importanttitle {
        min-width: 140px;
    }

    .notetitle, .importanttitle, .tiptitle, .videotitle, .warningtitle {
        &::before {
            display: inline-block;
            vertical-align: top;
            margin-right: $size-small/2;
        }
    }

    td.notetitle, td.tiptitle, td.importanttitle, td.videotitle, td.warningtitle {
        width: 17%;
    }

    td.note_entry {
        width: 83%;
        padding-left: 0px;
    }

    .notetitle::before {
        content: url('https://assets.extrahop.com/images/icons/eh_note_docs.png');
    }

    .importanttitle::before {
        content: url('https://assets.extrahop.com/images/icons/eh_important_docs.png');
    }

    .tiptitle::before {
        content: url('https://assets.extrahop.com/images/icons/eh_tip_docs.png');
    }

    .videotitle::before{
        content: url('https://assets.extrahop.com/images/icons/youtube-brands.svg');
        padding-top: 4px;
    }

    .warningtitle::before {
        content: url('https://assets.extrahop.com/images/icons/eh_warning_docs.png');
    }

    dl, .parml {
        margin-left: $size-small;
    }

    .table {
        @extend .table-bordered;

        &.anchor-point {
            border-top-width: 75px;
        }
    }

    table.choicetable {
        &.anchor-point {
            margin-top: -75px;
            border-top-width: 85px;
            border-top-color: transparent;
            border-right-width: 0px;
            border-left-width: 0px;
        }
    }

    caption.table-title {
        color: #424242;
    }

    .choicetable {
        @extend .table;
    }

    table.centered:not(.full-width) {
        width: inherit;
    }

    thead {
        tr {
            background-color: $bg-grayscale;
        }

        th {
            font-weight: 600;
        }
    }

    .tablenoborder table {
        border-color: transparent;
        overflow: auto;

        tr.row {
            td, th {
                border-color: transparent;
                border-bottom: 1px solid $gray-light;
            }
        }

        th.anchor-point {
            display: table-cell;
        }

    }

    table {
        tr.anchor-point{
            display: table-row;
            border-top: 0px;
        }

        td.anchor-point{
            display: table-cell;
            margin-top: 0px;
        }

        th.anchor-point {
            display: table-cell;
        }

    }

    table.tip, table.note, table.important, table.video, table.warning, table.danger {
        &.anchor-point {
            margin-top: 10px;
            border-top: 0px;
            display: table;
        }
    }

    td {
        ol, ul {
            margin-bottom: 0px;
        }
    }

    span.dt-entry {
        &.anchor-point {
            display: inline;
        }
    }

    span.pt-entry {
        &.anchor-point {
            display: inline;
        }
    }

    dd {
        margin-bottom: 8px;
    }

    kbd.userinput {
        background-color: transparent;
        color: $text-body;
        font-family: $font-family-monospace;
        box-shadow: none;
    }

    img {
        padding: $size-small/3;
        padding-bottom: 0px;

        max-width: 100%;

        &.anchor-point {
            display: inline;
            margin-top: 0px;
            border-top: 0px;
        }
    }

    ul li {
        list-style: inherit;
    }

    li.anchor-point {
        display: list-item;
    }

    a[href*="customers"],
    a[href*="customers.extrahop.com"] {
        &::after {
            content: none; //override display of lock icon
        }
    }

    .NavigationAid {
        font-size: $font-size-small;
    }

    .CommandHeading, .collapse-head {
        font-weight: 600;
    }

    .UI-text, .UI-page {
        font-weight: normal;
    }

    .btn-basic {
        color: $white;
        background-image: linear-gradient(to right, $cyan, $cyan-dark);
        border-color: $cyan;
        border-width: 2px;
        border-radius: 20px;
        min-width: 75px;


        &:hover, &:focus {
            color: $black;
            background-color: $white;
            background-image: none;
        }
    }

    .sidebar-inner .nav li {
        font-weight: normal;
    }

    .pyhop-deprecated {
        background-color: $bg-colorized;
        margin-top: $size-medium;
        padding: $size-medium;
        color: $on-dark;

        a {
            color: $on-dark;
            border-color: $on-dark;
        }
    }

    ul.doc-checklist {
        list-style-type: none;
        margin-left: 0;

        li::before {
            font-family: FontAwesome;
            content: "\f096";
            margin-right: $size-small/3;
        }
    }

    .parml p {
        margin-bottom: 0px;

        .pt-entry, .pt-entry .keyword.wintitle {
            font-weight: 400;
        }
    }

    .prereq-title, .postreq-title {
        font-weight: 400;
    }

    .figcap {
        font-size: $font-size-small;
        font-weight: 400;
    }

    // consistent spacing between elements
    [class^="Note"], [class^="note"],
    [class^="example"], [class^="tip"],
    [class^="important"], [class^="video"],
    [class^="warning"],
    .prereq, .postreq,
    .block-div,
    .table, .choicetable,
    .parml p:last-child,
    p, dl, img {
        margin-bottom: $size-small;

        + .choicetable,
        + .codeblock,
        + .important,
        + .note,
        + .video,
        + .tip {
            margin-top: 0px;
        }
    }

    table.note, table.tip, table.important, table.video, table.warning {
        table-layout: fixed;
        width: 100%;
    }

    li dl {
        margin-bottom: 0px;

        + .choicetable,
        + .codeblock,
        + .important,
        + .note,
        + .video,
        + .tip {
            margin-top: $size-small;
        }
    }

    .collapse-section + .collapse-section,
    .choicetable,
    .codeblock,
    .important,
    .note,
    .video,
    .tip {
        margin-top: $size-small;
    }

    .lead-in, .figcap,
    .prereq-title, .post-req-title {
        margin-bottom: $size-small/3;
    }
}
