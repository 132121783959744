html, body, ul, ol, li, form, fieldset, legend, h1, h2, h3, h4, h5, h6, p
{
    margin: 0;
    padding: 0;
}

fieldset, img { border: 0; }
legend { color: #000; }

input, textarea, select, button
{
    font-size: 110%;
    line-height: 1.1;
}

button
{
    width: auto;
    overflow: visible;
}

table
{
    border-collapse: collapse;
    border-spacing: 0;
}

caption, th, td
{
    text-align: left;
    vertical-align: top;
}

caption, th {
    font-weight: normal;
}
ul li { list-style: none; }

abbr, acronym
{
    border-bottom: .1em dotted;
    cursor: help;
}

sup { vertical-align: text-top; }
sub { vertical-align: text-bottom; }

pre, code
{
    font-family: "Courier New", monospace, serif;
    font-size: 100%;
    line-height: 1.2;
}
