@import 'shared/header';

.header-logo {
    display: block !important;
    margin-top: 9px;
}

.nav-mobile li {
    list-style: none;
}
