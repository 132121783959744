@import 'shared/sidebar';

$vertical-scroll-button-height: $size-small * 3;
$gradient: $bg-base 55%, transparent;
$top-offset: $size-medium;

.sidebar {
    .sticky {
        position: relative;
        padding-top: $top-offset;

        &.affix {
            position: fixed;
            padding-top: 0px;
            top: $nav-height-prime + $top-offset;
        }
    }

    // this class is required to make affix bottom work.  Lame
    .affix-bottom {
        position: absolute;
    }

    .control-row {
        min-height: $input-height-base + 2px; // offset to actual button height
    }

    .control-row-plus{
        min-height: $input-height-base + 40px; // offset to actual button height
    }

    .version-switcher {
        position: absolute;
        left: 0px;
        min-width: 50%;

        & + .btn {
            position: absolute;
            right: 0px;
            max-width: 50%;
        }
    }

    .download-btn {
        min-width: 50% !important;
    }

    a.btn.toc-toggle {
        margin-top: $size-medium;
        margin-left: 0px;

        position: relative;
        width: 100%;

        &::after {
            font-family: FontAwesome;
            content: '\f039'; // fa-align-justify

            position: absolute;
            left: $size-small * 2;
            top: 0px;

            height: 100%;
            line-height: 41px; // align with text in button
        }
    }

    .vertical-scroll {
        position: relative;
        overflow: hidden;

        @include experimental(user-select, none);

        width: 100%;

        padding-top: $vertical-scroll-button-height;
        padding-bottom: $vertical-scroll-button-height;

        .vertical-scroll-button {
            position: absolute;
            z-index: $z-index-sticky + 1;

            width: inherit;
            height: $vertical-scroll-button-height;

            display: none;

            cursor: pointer;

            color: $link-color;

            &:hover, &:active, &:focus {
                color: $link-hover-color;
            }

            .fa-lg {
                vertical-align: middle;
            }

            padding-left: $size-small;
        }

        .vertical-scroll-up {
            top: 0px;
            background-image: linear-gradient(to bottom, $gradient);
        }

        .vertical-scroll-down {
            bottom: 0px;
            background-image: linear-gradient(to top, $gradient);
        }

        .vertical-scroll-target {
            position: relative;
            bottom: 0px;
        }
    }
}
