$flop-point: $size-flop-secondary;

@mixin column-sizes($number, $indent, $width, $gutter) {
    // number is the number of items
    // indent is the effective outer margin of the grid
    // width is the width of the items in the grid
    // gutter is the space between items in the grid
    // ($indent * 2) + ($number * $width) + (($number - 1) * $gutter) should approximate 100%

    @media screen and (min-width: $flop-point) {
        > .item {
            width: $width;
            margin-right: $gutter;

            &:nth-child(#{$number}n) {
                margin-right: $indent;
            }

            &:nth-child(#{$number}n+1) {
                clear: left;
                margin-left: $indent;

                @media screen and (max-width: $flop-point) {
                    margin-left: 0px;
                }
            }
        }
    }
}

.grid.row {
    > * {
        position: relative;
        min-height: 1px;
        padding-left: $size-small;
        padding-right: $size-small;

        @media screen and (min-width: $flop-point) {
            float: left;
        }
    }

    &.one {
        @include column-sizes(1, 25%, 50%, 0);
    }
    &.two {
        @include column-sizes(2, 8.5%, 40%, 3%);
    }
    &.two.full {
        @include column-sizes(2, 0%, 47.5%, 5%);
    }
    &.three {
        @include column-sizes(3, 0, 31.33333%, 3%);
    }
    &.four {
        @include column-sizes(4, 1%, 23%, 2%);
    }
    &.four.full {
        @include column-sizes(4, 0%, 24%, 1.33333%);
    }
    &.five {
        @include column-sizes(5, 1%, 18%, 2%);
    }

    .item {
        margin-bottom: $size-medium;
        padding: $size-small;

        > h3 {
            margin-top: 0px;
        }
    }

    &.logo {
        h3 { min-height: 50px; }
    }
}
