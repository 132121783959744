.has-tooltip {
    border-bottom: 1px dotted $link-color;
}

.tooltip.in {
    opacity: 1;
    filter: alpha(opacity=100);
}

.tooltip-inner {
    background-color: $near-black;
    padding: $size-small/3 $size-small;
    text-align: left;
    max-width: 250px;
}
