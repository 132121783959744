header {
    .search-input-wrapper {

        .search-input {
            position: absolute;
            right: 0px;

            outline: none;
            border: none; // reset
            border-bottom-width: 1px;
            border-bottom-style: solid;
            font-weight: normal;
            font-size: $font-size-large;
            line-height: $font-size-large * 2;

            width: 0%;
            background-color: transparent;
            border-color: transparent;
            pointer-events: none;
            color: transparent;

            transition-property: width, background-color;
            transition-duration: $transition-speed-search;
            transition-timing-function: ease-out;

            &:focus {
                box-shadow: 0 4px 4px -4px rgba($accent-grayscale-dark, 0.1);
            }

            &.st-default-search-input {
                background: none;
                top: $size-small;
                right: -$size-small/3;
                height: $size-medium;

                @media (max-width: $size-flop-secondary) {
                    position: static;
                }
            }
        }

        .search-go {
            position: absolute;

            right: 0px;

            display: none;
        }

        // pixel perfection via hackery is worth it in the site chrome
        // optimized for mac, not linux, though
        @-moz-document url-prefix() {
            .search-go {
                top: 0px;
            }
        }

        &.search-shown {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: $nav-height-prime;

            .search-input {
                padding: 1px;
                padding-left: $size-small / 2;
                padding-right: $size-small + $size-small / 2;

                width: 100%;
                border-color: $accent-grayscale-light;
                pointer-events: initial;
                color: $text-nav;

                &::-webkit-input-placeholder, // WebKit, Blink, Edge
                &:-moz-placeholder, // Mozilla Firefox 4 to 18
                &::-moz-placeholder, // Mozilla Firefox 19+
                &:-ms-input-placeholder { // Internet Explorer 10-11
                   color: $text-nav;
                   opacity: .8;
                }

                &:-webkit-autofill {
                    -webkit-box-shadow: 0 0 0 $size-flop-secondary $accent-grayscale-light inset !important;
                }
            }

            .search-go {
                display: block;
            }
        }
    }

    @media (min-width: $size-flop) {
        .navitems .search-input-wrapper {
            display: block;
        }
    }
}

.search-results {
    main {
        margin-top: 0px;
        padding-top: $size-medium;
    }

    .filters {
        .heading {
            margin-top: 0px;
            font-size: $font-size-large;
        }

        li {
            margin-left: $size-medium;

            @extend a;

            &:hover, &:focus {
                cursor: pointer;
                border-bottom-color: transparent;
            }

            &.filter-primary {
                color: $accent-dark;

                &::before {
                    color: $accent-dark;
                    font-family: FontAwesome;
                    content: "\f00c"; // checkmark space
                    margin-left: -$size-medium;
                    margin-right: $size-small;
                }
            }
        }
    }

    .summary {
        min-height: $size-medium + $size-small;
        text-align: right;

        .heading {
            font-size: $font-size-base;
        }
    }

    .search-results-error, .search-results-no-results {
        font-size: $font-size-base;
    }

    .search-results-error {
        color: $state-danger-text;
        line-height: 1.3;
    }

    .search-results-no-results {
        color: $state-warning-text;
    }

    .results {
        font-size: $font-size-base;

        .search-result {
            margin-bottom: $size-small;

            .result-title {
                font-weight: 600;
            }

            &.application-pdf .result-title::after {
                font-family: FontAwesome;
                font-weight: normal;
                content: '\f1c1'; // pdf icon
                margin-left: $size-small / 2;
            }

            .result-link, .result-snippet {
                margin-left: $size-small;

                width: 85%;
            }

            .result-snippet br {
                display: none;
            }
        }
    }
}

.st-default-autocomplete {
    top: -$size-small/2 !important;
}
