.doc-home, .doc-category {
    main {
        padding-top: $size-medium;
    }
}

.doc-category {
    h1 {
        img {
            vertical-align: bottom;
        }
    }

    .doc-item, h1 {
        padding-bottom: $size-medium;
    }

    .doc-item  {
        padding-top: $size-medium;
        border-top: $border-grayscale-accent;

        h2 {
            font-size: $font-size-large;
        }

        p {
            margin-bottom: 0px;
        }
    }

    .subheading {
        margin-left: $size-small;
    }

    .doc-subitem {
        margin-top: $size-medium;

        div:first-child {
            padding-left: $size-small * 3;
        }
    }
}

.doc-home {
    main {
        h2 {
            margin-top: $size-medium;

            a {
                color: $heading-sub;
                &:hover, &:focus {
                    color: $heading-sub;
                }
            }
        }

        .version-switcher-intro, .version-switcher {
            display: inline-block;
        }

        .search-input-wrapper {
            margin: $size-medium 0px;

            input {
                width: calc(100% - #{$size-small/3});

                @extend .form-control;

                display: inline-block;
            }

            .search-go {
                margin-left: -$size-medium;

                &:hover, &:focus {
                    color: $link-hover-color;
                }
            }
        }
    }
}
