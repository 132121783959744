@mixin idevice {
    @media
        only screen and (min-device-width: $size-ipad-portrait)
                    and (max-device-width: $size-ipad-landscape)
                    and (-webkit-min-device-pixel-ratio: 1),
        only screen and (min-device-width: $size-iphone-small-portrait)
                    and (max-device-width: $size-iphone-small-landscape)
                    and (-webkit-min-device-pixel-ratio: 2),
        only screen and (min-device-width: $size-iphone-large-portrait)
                    and (max-device-width: $size-iphone-large-landscape)
                    and (-webkit-min-device-pixel-ratio: 3) {
        @content
    }
}

$nav-border: 1px solid $gray-dark;

@import 'shared/nav-desktop';
@import 'shared/nav-mobile';
