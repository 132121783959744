$btn-min-width: 200px;

//Takes map that determines the text color of a button in a restful state
//and the background color for hover states and rest states and uses the
//bootstrap button creator to determine the button style
@mixin create-button($options) {
    $text-color: map-get($options, text-color-rest);
    $rest-color: map-get($options, bg-color-rest);
    $hover-color: map-get($options, bg-color-hover);
    @include button-variant($text-color, $rest-color, $hover-color);

    border: 1px solid $rest-color;

    &:hover, &:focus {
        background-color: $hover-color;
        border: 1px solid $rest-color;

        transition: all $transition-speed-link ease 0s;
    }
}

.btn, .nav li a.btn {
    font-family: $btn-font-family;
    font-size: $btn-font-size;
    font-weight: 600;
    min-width: $btn-min-width;

    margin-top: 10px;
    padding: 10px;

    &.emphatic {
        text-transform: uppercase;
    }

    [class^="col-"] & {
        margin-top: 5px;
    }

    &.m-top-none {
        margin-top: 0;
    }

    &.full-width {
        min-width: initial;
    }
}

.btn-seethrough {
    $options: (
        'text-color-rest': $btn-basic-hover,
        'bg-color-hover': $btn-basic-hover,
        'bg-color-rest': $white
    );
    @include create-button($options);

    &:hover, &:focus {
        background-color: transparent;
        color: $on-dark;
        border: 1px solid $on-dark;

        .on-dark & {
            color: $link-hover-color;
        }
    }

    &:active {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}

.btn.embiggened {
    font-size: $font-size-large;
}

.btn-container {
    width: $btn-min-width;
}

.btn-basic, .hero .btn-basic, .btn-header {
    $options: (
        'text-color-rest': $white,
        'bg-color-hover': $btn-basic-hover,
        'bg-color-rest': $btn-basic-rest
    );
    @include create-button($options);

    &:hover, &:focus {
        color: $white;
    }
}

.btn-skinny {
    min-width: $btn-min-width / 2;
}

.btn-header {
    margin-top: 0px;
    min-width: $btn-min-width / 2;
    line-height: 1;
}

button {
    &.reset {
        border: none;
        background-color: transparent;
        color: inherit;
    }

    &.target-collapsed, &.target-collapsing,
    &.target-expanded, &.target-expanding {
        @extend button.reset;

        border-bottom: 1px solid transparent;
        color: $link-color;
        font-weight: normal;

        &:hover, &:focus {
            border-bottom-color: transparent;
            color: $link-hover-color;
        }
    }
}
