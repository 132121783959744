footer {
    $text-color: $gray-pale;

    min-height: $footer-height;
    margin-top: $footer-spacing;

    .hero + &, .breadcrumbs + &, .snap-to-footer + & {
        margin-top: 0px;
    }

    padding: $size-small 0px;

    z-index: $z-index-chrome;

    background-color: #1D1D1D;
    color: $text-color;
    font-size: $font-size-small;

    @include link-setup($text-color, $on-dark);

    a:not(.btn), .locations strong {
        opacity: .75;
    }

    a:hover, a:focus {
        border: none;
        opacity: 1;
    }

    h4 {
        color: $text-color;
    }

    .mega-footer {
        .grid.row .item {
            margin-bottom: $size-small/3;
        }

        h4 {
            text-transform: uppercase;
            font-size: 14px;
            margin-bottom: $size-small/4;

            a {
                opacity: 1;
            }
        }

        ul {
            margin-bottom: $size-small;
        }

        li {
            margin-bottom: $size-small/4;
        }
    }

    .mega-footer + .thin-footer {
        border-top: 1px solid $gray-dark;
        padding-top: $size-small;
        margin-top: $size-small;
    }

    .legal-notice {
        font-size: $font-size-mini;
    }

    .social {
        text-align: right;
    }

    .footer-logo {
        height: $fixed-height-brand-image;
        width: $fixed-width-brand-image;
        background-image: url($extrahop-logo-url-white);
        background-size: contain;
        background-repeat: no-repeat;
        margin: $size-small/2 0px;

        opacity: .9;

        &:hover, &:focus {
            opacity: 1;
        }
    }

    @media screen and (min-width: $size-flop-secondary) {
        .company {
            padding-top: $size-small;
        }
    }

    @media screen and (max-width: $size-flop-secondary) {
        text-align: center;

        .footer-logo {
            margin-left: auto;
            margin-right: auto;
        }

        .grid.row .item {
            padding: 0px;

            ul {
                margin-bottom: 0px;
            }
        }

        .mega-footer + .thin-footer {
            margin-top: $size-small;
        }

        .social {
            text-align: inherit;
        }
    }
}

// Sticky Footer Classes
.sticky-footer-helper {
    min-height: 100%;
    // Footer is height tall with size-medium padding on top
    // The +1 is necessary to avoid having the page scroll by 1 pixel
    margin: 0 auto (($footer-height+$size-medium+1)*-1);

    .sticky-footer-spacer {
        height: $footer-height+$size-medium;
    }
}
