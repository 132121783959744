$highlight-color: $accent-darkest;

aside, .sidebar {
    margin-top: $body-spacing;

    h2, h3 {
        &:first-child {
            margin-top: 0px;
        }
    }

    img {
        max-width: 100%;
    }
}

main {
    .sidebar, aside {
        margin-top: 0px;
    }
}

aside {
    .sidebar & {
        margin-top: 0px;
    }

    &.affix h2:first-of-type {
        margin-top: $size-small;
    }

    font-size: $font-size-small;

    h2, h3 {
        margin-bottom: 0px;
    }

    h2 {
        font-size: $font-size-h3;

        & + p {
            margin-top: $size-small;
        }
    }

    h3 {
        font-size: $font-size-large;
    }

    h4, h5 {
        font-size: $font-size-small;
    }

    p {
        margin-bottom: $line-height-computed / 2;
    }
}

.sidebar-box {

    border: $border-grayscale-accent;

    > div {
        padding: $size-small $size-medium;
        text-align: center;

        &.text-left {
            text-align: left;
        }
    }

    .cap {
        background-color: $bg-gray-darker;

        h1, h2, h3, h4, h5, h6 {

            margin-bottom: 0px;
        }

        h3 {
            font-size: $font-size-large;
        }
    }

    .body p {
        margin-bottom: $size-small/2;
    }

    @media (max-width: $size-flop) and (min-width: $size-flop-secondary) {
        .body .btn {
            margin-left: -$size-small;
        }
    }
}

.affix .sidebar-inner h2:first-child {
    margin-top: $size-small;
}

.sidebar-inner {
    $side-padding: $size-small;
    $vert-padding: $size-small / 4;
    $vert-padding-small: $vert-padding / 2;
    $active-outdent: $size-small / 3;

    width: inherit;

    h2 {
        font-size: $font-size-large;
        margin-bottom: 0px;
    }

    .nav {
        margin-left: 0px;

        font-size: $font-size-small;
        font-weight: 600;

        a:not(.btn) {
            &:hover, &:focus {
                border: none;
            }
        }

        li {
            padding: $vert-padding $side-padding;

            a {
                &:focus, &:hover {
                    color: $highlight-color;
                }
            }

            &.active {
                // outdent active item slightly
                padding-left: $side-padding - $active-outdent;

                > a {
                    color: $highlight-color;
                }
            }
        }

        .nav {
            // hide sidebar sub menus (nav inside nav) by default
            display: none;

            li {
                padding-top: $vert-padding-small;
                padding-bottom: $vert-padding-small;
            }
        }

        // show sub menu when parent is active
        .active > .nav {
            display: block;
        }
    }
}
