@import 'shared/login-logout';
@import 'shared/nav-menus';

// mixins not necessarily used in shared header, but
// are used in property-specific overrides
@mixin set-nav-item-width($number-nav-items) {
    width: calc(100% / #{$number-nav-items});
}

@mixin non-dropdown-nav-item($number-nav-items) {
    @include set-nav-item-width($number-nav-items);

    a {
        padding: 0;
        display: block;
    }
}

header {
    // full width
    // * full width brand
    // * full nav
    // * search icon
    // * log in/out
    // * local home if applicable
    // * CTA button if applicable

    position: fixed;
    top: 0px;
    background-color: rgba(0, 0, 0, .97);
    z-index: $z-index-chrome;
    width: 100%;
    min-height: $nav-height-prime;

    line-height: $nav-height-prime;
    font-family: $nav-font-family;
    font-size: $nav-font-size;
    font-weight: $nav-font-weight;
    color: $text-nav;

    @include link-setup-nav($text-nav, $text-nav-hover);

    .topnav > div {
        display: inline-block;
        position: relative;
        float: left;
    }

    .brand {
        left: 0;
        width: $fixed-width-brand;
        margin-top: $nav-height-prime/4;

        .header-logo, .header-logo-icon {
            height: $fixed-height-brand-image;
            background-size: contain;
            background-repeat: no-repeat;
        }

        .header-logo {
            width: $fixed-width-brand-image;
            background-image: url($extrahop-logo-url-white);
        }

        .header-logo-icon {
            display: none;
            width: $fixed-width-brand-icon;
            background-image: url($extrahop-logo-icon-url);
        }
    }

    // styles here are for rendering the navitems in the header directly
    // styles for various dropdown menus, including mobile, defined in nav-menus
    .navitems {
        width: calc(100% - #{$fixed-width-brand} - #{$fixed-width-utils});

        & > div, nav {
            display: inline-block;
            float: left;
        }

        nav {
            width: 100%;
            height: $nav-height-prime;
            text-align: center;
        }

        .current-page {
            color: $text-current;
        }

        .nav-links > li {
            @include set-nav-item-width($fixed-number-nav-items);

            position: relative;
            float: left;

            > a {
                padding: 0px;
                display: block;
            }
        }
    }

    button {
        padding-top: 0px;
        padding-bottom: 0px;

        &:hover, &:focus {
            color: $text-nav-hover;
        }
    }

    .utilities {
        width: $fixed-width-utils;

        @extend .pull-right;
        text-align: right;
        white-space: nowrap;

        > div {
            display: inline-block;
            margin-right: $size-small;

            &.mobile {
                display: none;
            }
        }

        .search {
            float: left;
        }

        .loginout {
            min-width: 55px;
            padding-bottom: $size-small;
        }
    }

    // tablet width
    // * full width brand
    // * hamburger icon
    // * search icon
    // * log in/out
    // * local home if applicable
    // * CTA button if applicable
    @media (max-width: $size-flop) {
        $fixed-width-utils-tablet: $fixed-width-utils - $size-small;

        .topnav {
            position: relative;
            text-align: center;

            & > div {
                position: absolute;
            }
        }

        .navitems {
            width: calc(100% - #{$fixed-width-brand} - #{$fixed-width-utils-tablet});
            right: #{$fixed-width-utils-tablet};

            nav {
                display: none;
            }
        }

        .utilities {
            width: $fixed-width-utils-tablet;

            right: 0;

            > div {
                margin-right: $size-small / 2;

                &.mobile {
                    display: inline-block;
                    margin-right: 0px;
                }
            }
        }
    }

    // below tablet width
    // * icon width brand
    // * hamburger icon
    // * search icon
    // * log in/out
    // * local home if applicable
    // * NO CTA
    @media (max-width: $size-flop-secondary) {
        $fixed-width-utils-below-tablet: $fixed-width-utils / 1.5;

        .brand {
            width: $fixed-width-brand-icon;
            padding-top: $size-small/6;

            .header-logo {
                display: none;
            }

            .header-logo-icon {
                display: block;
            }
        }

        .navitems {
            width: calc(100% - #{$fixed-width-brand-icon} - #{$fixed-width-utils-below-tablet});
            right: #{$fixed-width-utils-below-tablet};
        }

        .utilities {
            width: $fixed-width-utils-below-tablet;

            .cta {
                display: none;
            }
        }
    }
}
