.error {
    &.error404 {
        #space {
            width: 380px;
            height: 380px;
            margin: 0px auto;
        }

        #starfield {
            width: 100%;
            height: 100%;

            background: url('https://assets.extrahop.com/images/banners/error-graphic-404-space.png');

            $animation-starfield: starfield 100000ms linear infinite;

            -webkit-animation: $animation-starfield;
            -moz: $animation-starfield;
            animation: $animation-starfield;
        }
        @-moz-keyframes starfield {
            from { -moz-transform: rotate(0deg); }
            to { -moz-transform: rotate(360deg); }
        }
        @-webkit-keyframes starfield {
            from { -webkit-transform: rotate(0deg); }
            to { -webkit-transform: rotate(360deg); }
        }
        @keyframes starfield {
            from { transform: rotate(0deg); }
            to { transform: rotate(360deg); }
        }

        #astronaut {
            width: 240px;
            height: 245px;
            margin-top: -320px;
            margin-left: 100px;

            background: url('https://assets.extrahop.com/images/banners/error-graphic-404-astronaut.png') no-repeat;

            $animation-astronaut: astronaut 40000ms linear infinite;

            -webkit-animation: $animation-astronaut;
            -moz-animation: $animation-astronaut;
            animation: $animation-astronaut;
        }
        @-ms-keyframes astronaut {
            from { -ms-transform: rotate(360deg); }
            to { -ms-transform: rotate(0deg); }
        }
        @-moz-keyframes astronaut {
            from { -moz-transform: rotate(360deg); }
            to { -moz-transform: rotate(0deg); }
        }
        @-webkit-keyframes astronaut {
            from { -webkit-transform: rotate(360deg); }
            to { -webkit-transform: rotate(0deg); }
        }
        @keyframes astronaut {
            from { transform: rotate(360deg); }
            to { transform: rotate(0deg); }
        }
    }
}
