ul.with-bullets {
    margin-left: $size-small;

    > li {
        list-style: initial;
    }
}

.markdown-content {
    .fa-ul {
        margin-left: 23px;

        li {
            list-style: none;
        }
    }
}

.inline-delim {
    li {
        display: inline-block;

        &::after {
            content: $delim-decoration;
            padding: 0px 10px;
        }

        &:last-child::after {
            content: "";
            padding: 0px;
        }
    }
}

.checklist-progress, .markdown-content .checklist-progress {
    margin-left: 0px;
    list-style-type: none;

    li {
        &::before {
            font-family: FontAwesome;
            margin-left: 19px;
            margin-right: 5px;
        }

        &.completed::before {
            color: $state-success-text;
            margin-left: 0px;
        }
    }
}

ol.checklist-progress {
    li {
        counter-increment: step-counter;

        &::before {
            content: counter(step-counter) ".";
        }

        &.completed {
            &::before {
                content: "\f00c\00a0" counter(step-counter) "."; // checkmark space number period
            }

            // for ordered checklist, the next list item after a completed one should be bold
            &+ li:not(.completed) {
                font-weight: bold;
            }
        }
    }
}

ul.checklist-progress, .markdown-content ul.checklist-progress {
    li {
        list-style-type: none;

        &::before {
            content: "\2022"; // bullet
        }

        &.completed::before {
            content: "\f00c\00a0\2022"; // checkmark space bullet
        }
    }
}

.checklist, .markdown-content ul.checklist {
    margin-left: 0px;
    list-style-type: none;

    li {
        list-style-type: none;
        margin-bottom: 0px;
        padding: $size-small $size-medium;
        padding-left: 0px;
        position: relative;

        &.checked {
            &::after {
                position: absolute;
                top: 25%;
                right: 0;

                font-family: FontAwesome;
                content: "\00a0\f00c"; // space checkmark
                font-size: $font-size-large;

                color: $accent-dark;
            }

            &:not(:last-child) {
                border-bottom: $border-grayscale-accent;
            }
        }
    }
}

.list-spacey li {
    margin-bottom: $size-small/2;
}

.fa-stack-numbers {
    float: left;
    margin-right: 10px;
    margin-top: 5px;
}
