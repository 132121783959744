.header-logo {
    display: block;
    margin-top: 9px;
}

.demo-btn {
    background-color: $plum;
    border-color: $plum;
    border-radius: 20px;
    &:hover, &:focus {
        background-color: $plum-dark;
        border-color: $plum;
        border-radius: 20px;
    }
}

.doc-driven-home {
    .hero {
        text-align: center;
        color: $white;
        padding-top: $size-small * 3;

        background: linear-gradient(to right, rgba(38,31,99, 0.8),rgba(127,40,84, 0.8)),url('https://cloud-assets.extrahop.com/external/docs/docs-bg.jpg');
        background-size: cover;

        @include link-setup($on-dark, $on-dark);

        h1 {
            white-space: nowrap;
            width: 100%;
        }

        h1, .version-switcher.single {
            color: $white;
        }

        .version-switcher {
            background-color: transparent;
            margin-left: $size-medium;

            &::after, select {
                color: $white;
            }

            select {
                background-color: transparent;

                option {
                    color: $white;
                    background-color: $on-dark;
                }
            }
        }

        .language-switcher {
            background-color: transparent;
            margin-left: $size-medium;

            &::after, select {
                color: $on-dark;
            }

            select {
                background-color: transparent;

                option {
                    color: $text-color;
                    background-color: $on-dark;
                }
            }
        }

        .search-input-wrapper {
            position: relative;
            width: 80%;

            margin: $size-medium auto;

            input {
                @extend .form-control;
            }

            .search-go {
                @extend .btn-basic;
                width: $size-large + $size-small;
                height: $input-height-base;

                position: absolute;
                top: 0;
                right: 0;

                .fa-search::before {
                    font-family: $btn-font-family;
                    content: 'Search';
                }
            }
        }

        @media (max-width: $size-iphone-small-landscape) {
            h1 {
                white-space: inherit;
            }

            .version-switcher {
                margin-left: 0px;
            }

            .search-input-wrapper .search-go {
                position: relative;
                margin-top: $size-small;
            }
        }
    }

    .subhero {
        $height: $size-large;
        $relnotes-width: 170px;

        background-color: $nav-bg-color;
        @include link-setup($on-dark, $on-dark);

        min-height: $height;

        .container {
            position: relative;
        }

        h2, .release-notes {
            line-height: $height;
            color: $on-dark;
            display: inline-block;
        }

        h2 {
            font-size: $font-size-base;
            font-weight: normal;
            margin: 0px;
            margin-right: $size-medium;
        }

        .list-inline {
            display: inline-block;
            line-height: $line-height-base;
            margin-left: 0px;
            margin-bottom: 0px;

            li {
                margin-right: $size-small;
                padding: 0px;
            }

            a {
                font-size: $font-size-med;
                font-weight: normal;
            }
        }

        .release-notes {
            text-align: center;
            display: inherit;

            a.all-link {
                background-color: lighten($bg-darkest, 5%);

                padding: $size-small/2 $size-medium;
                @extend .effectless;
                font-size: $font-size-med;

                &:hover, &:focus {
                    background-color: $bg-darkest;
                }
            }
        }

        .release-notes + .release-notes {
            position: absolute;
            top: 0;
            right: $size-small;

            width: $relnotes-width;
        }
    }

    main {
        .grid {
            margin-top: -$size-small;
        }

        h2 {
            a {
                color: $heading-sub;
                &:hover, &:focus {
                    color: $heading-sub;
                }
            }
        }

        li {
            line-height: 1;
            margin-bottom: $size-small/2;
            font-weight: normal;
        }
    }

    button.search-open {
        display: none;
    }

    div.search-open {
        display: none;
    }

    .st-default-autocomplete {
        top: 185px !important;

        @media (min-width: 520px) and (max-width: $size-flop) {
            top: 170px !important;
        }

        @media (min-width: 385px) and (max-width: 520px) {
            top: 210px !important;
        }

        @media (max-width: 385px) {
            top: 250px !important;
        }
    }
}

.doc-driven-category {
    .hero h1 img {
        vertical-align: bottom;
        max-height: $size-large;
    }

    main {
        h2.title {
            display: none;
        }

        h3 {
            @extend .anchor-point;

            &:first-child {
                margin-top: -$anchor-point-offset;
            }
        }

        li {
            font-weight: normal;
        }
    }

    .sidebar {
        margin-top: $size-small*3;

        h2 {
            font-size: $font-size-large;
            margin-top: $size-small/2;
        }

        ul {
            font-size: $font-size-med;
        }

        li {
            font-weight: normal;
        }
    }
}

.doc-driven-sitemap {
    .switcher {
        padding-top: $size-small/3;
        padding-right: 0px;

        font-size: $font-size-large;

        div {
            display: inline-block;
        }

        .version-switcher-intro {
            vertical-align: middle;
            margin-right: $size-small/2;
        }

        @media (min-width: $size-flop-secondary) {
            text-align: right;
        }
    }

    main {
        .body {
            margin-left: $size-medium;
        }

        .topic {
            margin-top: $size-small;
        }

        .shortdesc {
            display: none;
        }

        li {
            font-weight: normal;
        }
    }
}

.doc-driven-category, .doc-driven-sitemap {
    main {
        margin-top: $size-small*3;

        .section + .section {
            margin-top: $size-small/2;
        }

        .sectiontitle {
            color: $accent-dark;
            margin-top: 0px;
            margin-bottom: 0px;
            padding: $size-small/3 0px
        }

        .expand-collapse {
            .target-collapsed, .target-collapsing,
            .target-expanded, .target-expanding {
                border-color: transparent;

                &:hover, &:focus {
                    border-color: transparent;
                }

                &::after {
                    content: '';
                }

                &::before {
                    font-family: FontAwesome;
                    margin-right: $size-small/2;
                }
            }

            .target-collapsed, .target-collapsing {
                &::before {
                    content: '\f107'; // fa-chevron-down
                }
            }

            .target-expanded, .target-expanding {
                &::before {
                    content: '\f106'; // fa-chevron-up
                }
            }

            .wrapper {
                padding-top: $size-small/3;

                .anchor-point {
                    margin-top: -75px;
                }
            }
        }

        li + li {
            margin-top: $size-small/3;
        }
    }
}
