// desktop dropdowns
.navitems nav {
    &.minimal, &.mega {
        .backing, .subnav {
            top: $nav-height-prime;

            height: 0px;
        }

        .backing {
            background-color: $nav-bg-color;
            transition: height .2s; // close speed
        }

        &.menu-opened .backing {
            transition: height .2s; // open speed
        }

        &.menu-opened .current-page {
            color: $text-nav;

            &:hover, &:focus {
                color: $text-nav-hover;
            }
        }

        .top-level-item {
            &.subnav-opened .subnav {
                padding: $size-medium;
                opacity: 1;
                transition: opacity .1s;

                &.scrollable {
                    overflow-y: scroll;
                }
            }

            .subnav {
                margin: 0px;

                background-color: $nav-bg-color;
                font-size: $nav-font-size;
                text-align: left;

                padding: 0px;
                opacity: 0;

                .linkset-title {
                    text-transform: uppercase;
                    padding-bottom: $size-small / 2;
                    border-bottom: $nav-border;
                    margin-bottom: $size-small;
                    line-height: initial;
                }

                li {
                    padding-bottom: $size-small / 2;
                    line-height: 1.2;

                    &:last-child {
                        margin-bottom: 0px;
                    }
                }
            }


            .focusable {
                cursor: default;

                &:focus {
                    outline-style: auto;
                    outline-width: medium;
                }
            }
        }
    }

    // basic dropdowns
    &.minimal {
        .backing, .subnav {
            position: absolute;
            left: -$size-small;
        }

        &.menu-opened .backing, .subnav-opened .subnav {
            height: initial;
        }

        .subnav-opened .subnav .container {
            display: block;
        }

        .subnav {
            .container {
                display: none;
                width: 200px;
                padding: 0px;
            }

            &.groups-1 .linkset-title {
                display: none;
            }

            .linkset {
                margin-top: $size-medium;

                &:first-child {
                    margin-top: 0px;
                }

                li {
                    font-size: $font-size-base;
                    font-weight: $base-font-weight;

                    a {
                        font-weight: $base-font-weight;
                    }
                }
            }

            .promo {
                display: none;
            }
        }
    }

    // wide panel including optional promo
    &.mega {
        .backing, .subnav {
            position: fixed;
            left: 0;
            width: 100%;
        }

        &.menu-opened .backing, .subnav-opened .subnav {
            height: 280px;
        }

        .top-level-item {
            &.subnav-opened .subnav {
                padding-left: 0px;
                padding-right: 0px;
            }

            .subnav {
                overflow: hidden;

                .linkset {
                    float: left;
                    margin-right: $size-medium;
                    width: calc(25% - #{$size-medium});

                    &.double-width {
                        width: calc(50% - #{$size-medium});
                    }
                }

                &.groups-1 .linkset {
                    width: 40%;
                }

                ul.columned {
                    columns: 2;
                    -moz-column-count: 2;

                    > * {
                        -webkit-column-break-inside: avoid;
                    }
                }

                .promo {
                    $promo-text-color: $gray-med;

                    float: right;
                    margin-right: 0px;
                    padding-right: $size-small;

                    color: $promo-text-color;
                    line-height: initial;

                    h3 {
                        color: $text-nav;
                        margin-top: 0px;
                    }

                    .quotes {
                        color: $promo-text-color;
                    }

                    img {
                        max-width: 100%;
                    }

                    img + p {
                        margin-top: $size-small / 2;
                    }

                    a {
                        color: $text-nav-hover;
                        font-weight: normal;

                        &:hover, &:focus {
                            color: $text-nav;
                        }

                        &.cta {
                            font-size: $font-size-base;

                            &::after {
                                margin-left: $size-small/3;

                                content: url('https://assets.extrahop.com/images/icons/arrow-teal-navmenu.png');
                                vertical-align: sub;
                            }

                            &:focus, &:hover {
                                transition: 0s; // override, as the content cannot transition to match the link

                                &::after {
                                    content: url('https://assets.extrahop.com/images/icons/arrow-white.png');
                                }
                            }
                        }
                    }
                }

                &.groups-2 .promo {
                    width: calc(50% - #{$size-large});
                }

                &.groups-4 .promo {
                    display: none;
                }
            }
        }
    }
}
