$menu-width: $size-flop-minimum;
$menu-background: $bg-darkest;
$menu-hover: lighten($menu-background, 5%);
$menu-background-secondary: lighten($menu-background, 10%);

.exclusive-window-wrapper {
    z-index: $z-index-overlay-panel;

    overflow: hidden;
    &.scrollable {
        overflow-y: scroll;
    }

    position: fixed;
    top: $nav-height-prime;
    right: 0;
    bottom: 0;
    left: calc(100% - #{$menu-width});

    transition-property: left, right;
    transition-duration: .5s;
}

// mobile styles (tablet portrait and down)
.nav-mobile-backing {
    background-color: $nav-bg-color;

    &:not(.hidden) {
        box-shadow: 5px 0px 30px 5px $nav-bg-color;
    }

    &.exclusive-window-wrapper.hidden {
        @media (min-width: $size-flop) {
            visibility: hidden !important;
            display: none !important;
        }
    }

    @media (min-width: $size-flop) {
        display: none !important;
    }
}

.nav-mobile {
    max-width: $menu-width;
    padding: 0px;
    font-family: $nav-font-family;
    color: $text-nav;

    @include link-setup-nav($text-nav, $text-nav-hover);

    h2, h3, h4 {
        color: $text-nav;
        margin: 0px;
    }

    h2 {
        font-size: $font-size-large;
    }

    h3, h4 {
        font-size: $font-size-base;
    }

    a {
        font-weight: $base-font-weight;
    }

    $mobile-section-height: $size-large;
    $general-offset: $size-medium;

    .linkset-section {
        min-height: $mobile-section-height;
        border-bottom: $nav-border;

        &:hover, &:focus {
            background-color: $menu-hover;
        }

        h2 {
            line-height: $mobile-section-height;
        }

        h3, h4 {
            line-height: $mobile-section-height/2;
        }

        h2, h3, h4 {
            padding-left: $general-offset;
        }

        .opener {
            position: relative;

            &:hover, &:focus {
                cursor: pointer;
            }

            &:active {
                color: $text-nav-hover;
            }

            &::after {
                font-family: FontAwesome;
                position: absolute;
                right: $general-offset;
                top: 2px;
                content: "\f107"; // fa-angle-down
            }

            &.selected::after {
                content: "\f106"; // fa-angle-up
            }
        }

        h3.opener {
            &:hover, &:focus, &.selected {
                background-color: $menu-hover;
            }

            &::after {
                top: 0px;
                padding-right: 2px;
            }
        }

        .links {
            display: none;

            margin: 0px;
            padding: 0px;
            background-color: $menu-background-secondary; // second-level-links get lighter background for contrast
        }

        > .links {
            border-top: $nav-border;
            background-color: $menu-background; // first-level links get basic background
        }
    }
}
