  $extrahop-screenshot-name: 'extrahop-screenshot';
  $extrahop-screenshot-x: 0px;
  $extrahop-screenshot-y: 0px;
  $extrahop-screenshot-offset-x: 0px;
  $extrahop-screenshot-offset-y: 0px;
  $extrahop-screenshot-width: 256px;
  $extrahop-screenshot-height: 196px;
  $extrahop-screenshot-total-width: 256px;
  $extrahop-screenshot-total-height: 216px;
  $extrahop-screenshot-image: '/img/sprites/sprite.png';
  $extrahop-screenshot: 0px 0px 0px 0px 256px 196px 256px 216px '/img/sprites/sprite.png' 'extrahop-screenshot';
  $magnifying-glass-icon-name: 'magnifying-glass-icon';
  $magnifying-glass-icon-x: 0px;
  $magnifying-glass-icon-y: 200px;
  $magnifying-glass-icon-offset-x: 0px;
  $magnifying-glass-icon-offset-y: -200px;
  $magnifying-glass-icon-width: 16px;
  $magnifying-glass-icon-height: 16px;
  $magnifying-glass-icon-total-width: 256px;
  $magnifying-glass-icon-total-height: 216px;
  $magnifying-glass-icon-image: '/img/sprites/sprite.png';
  $magnifying-glass-icon: 0px 200px 0px -200px 16px 16px 256px 216px '/img/sprites/sprite.png' 'magnifying-glass-icon';

  $all_icons: (
      $extrahop-screenshot
      $magnifying-glass-icon
  );

  @mixin sprite-width($sprite) {
    width: nth($sprite, 5);
  }

  @mixin sprite-height($sprite) {
    height: nth($sprite, 6);
  }

  @mixin sprite-position($sprite) {
    $sprite-offset-x: nth($sprite, 3);
    $sprite-offset-y: nth($sprite, 4);
    background-position: $sprite-offset-x  $sprite-offset-y;
  }

  @mixin sprite-image($sprite) {
    $sprite-image: nth($sprite, 9);
    background-image: url(#{$sprite-image});
  }

  @mixin sprite($sprite) {
    @include sprite-image($sprite);
    @include sprite-position($sprite);
    @include sprite-width($sprite);
    @include sprite-height($sprite);
  }

  .icon-extrahop-screenshot {
      @include sprite($extrahop-screenshot);
  }
  .icon-magnifying-glass-icon {
      @include sprite($magnifying-glass-icon);
  }
