.hero {
    min-height: $height-hero;

    &:not(.plain) {
        color: $text-body;
        border-bottom: 1px solid $accent-grayscale-light;

        h1 {
            color: $text-body;
        }

        h2, h3, h4, h5, h6, .quote, .attribution {
            color: $text-body;
        }

        a:not(.btn) {
            color: $link-color;

            &:hover, :focus {
                color: $link-hover-color;
                border-color: $link-hover-color;
            }
        }
    }

    padding: $size-small+$size-small/2 0px;

    font-weight: $hero-font-weight;

    > .container:not(.unconstrained) {
        > h1, > h2, > h3, > p {
            &:not(.unconstrained) {
                width: $hero-constrained;
            }
        }
    }

    h1 {
        margin-top: 0px;
    }

    h2 {
        font-size: $font-size-large;
    }

    &.heavyweight {
        font-weight: normal;

        h2, h3, h4, h5, h6 {
            font-weight: normal;
        }
    }

    &.short {
        min-height: $height-hero-short;
    }

    &.minimal {
        min-height: $height-hero-minimal;
    }

    &.full-page {
        min-height: $height-hero-full;
    }

    .hero-image {
        text-align: right;

        [class^="logo"] { margin: $size-medium 10%; }

        @media (max-width: $size-flop) {
            display: none;
        }
    }

    h1 + h2 {
        margin-top: 0px;
    }

    hr {
        border-top-color: $on-dark-muted;
    }

    img {
        max-width: 100%;

        &:not(.omnipresent) {
            @media (max-width: $size-flop) {
                display: none;
            }

            &.hero-internal {
                max-width: 100%;
                max-height: 100%;
                padding-top: $size-medium;
            }
        }
    }

    .btn:not(.embiggened) {
        font-weight: 600;
    }

    @media (max-width: $size-flop) {
        h1 {
            font-size: $font-size-h2;
        }

        > .container:not(.unconstrained) {
            > h1, > h2, > h3, > p {
                &:not(.unconstrained) {
                    width: 100%;
                }
            }
        }
    }

    @media (max-width: $size-flop-secondary) {
        text-align: center;
    }
}

.hero-text {
    margin-right: 10%;
}
