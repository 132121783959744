// Get everything reasonable that we can from www
@import 'shared/common';

body {
    position: relative;
}

.top-of-page-container {
    font-size: $font-size-small;

    a {
        opacity: .5;

        &:hover, &:focus {
            opacity: 1;
        }
    }
}

main {
    a[href*="customers"],
    a[href*="customers.extrahop.com"] {
        @extend .secured;
    }

    a[href*="customer.extrahop.com/training/link"]::after {
        content: "";
        margin-left: 0;
    }
}

