$control-button-width: 125px;

.language-switch {
    > * {
        display: inline-block;
    }

    .extended-label {
        margin-right: $size-small / 2;
    }

    .language-switcher {
        font-size: $font-size-base;
    }
}

.language-switcher {

    &.single {
        background-color: transparent;
    }

    color: $text-body;
    font-size: $font-size-large;

    display: inline-block;
    vertical-align: middle;
    position: relative;

    &:not(.single) {
        width: $control-button-width;

        > * {
            cursor: pointer;
        }

        &::after {
            font-family: FontAwesome;
            content: '\f107'; // fa-angle-down

            position: absolute;
            top: 0px;
            right: $size-small/2;
            line-height: $input-height-base;
            padding-top: 1px;
            pointer-events: none;

            color: $text-body;
        }

        select {

            @extend .form-control;
            outline: none;
            border: none;
            display: inline-block;
            height: $input-height-base + 2px; // same height as buttons with their border

            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;

            padding: 0px $size-small;
        }
    }

    a.original-language {
        font-size: $font-size-med;
        position: absolute;
        display: block;
    }

    + .btn {
        margin-top: 0px;
        margin-left: $size-medium;
    }
}

.version-switch {
    > * {
        display: inline-block;
    }

    .extended-label {
        margin-right: $size-small / 2;
    }

    .version-switcher {
        font-size: $font-size-base;
    }
}

.version-switcher {
    background-color: $gray-med;

    &.single {
        background-color: transparent;
    }

    color: $text-body;
    font-size: $font-size-large;

    display: inline-block;
    vertical-align: middle;
    position: relative;

    &:not(.single) {
        width: $control-button-width;
        border-radius: 25px;

        > * {
            cursor: pointer;
        }

        &::after {
            font-family: FontAwesome;
            content: '\f107'; // fa-angle-down

            position: absolute;
            top: 0px;
            right: $size-small/2;
            line-height: $input-height-base;
            padding-top: 1px;
            pointer-events: none;

            color: $text-body;
        }

        select {
            background-color: $gray-med;

            @extend .form-control;
            outline: none;
            border: none;
            border-radius: 25px;
            display: inline-block;
            height: $input-height-base + 2px; // same height as buttons with their border

            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;

            padding: 0px $size-small;
        }
    }

    a.original-version {
        font-size: $font-size-med;
        position: absolute;
        display: block;
    }

    + .btn {
        margin-top: 0px;
        margin-left: $size-medium;
    }
}

.buildstamp {
    font-size: $font-size-small;
}

.feedback {
    margin-top: $size-medium;

    font-size: $font-size-small;

    @mixin colorizeButton($buttonMode) {
        $options: ();

        @if $buttonMode == 'normal' {
            $options: (
                'text-color-rest': $text-body,
                'bg-color-hover': $gray-pale,
                'bg-color-rest': $bg-base,
                'border-color': $gray-med
            );
        }

        @if $buttonMode == 'checked' {
            $options: (
                'text-color-rest': $bg-base,
                'bg-color-hover': $gray-dark,
                'bg-color-rest': $near-black,
                'border-color': $near-black
            );
        }

        @include create-button($options);

        // override various styles from bootstrap buttons
        border-color: map-get($options, border-color);
    }

    .buttoned-radios {
        margin-top: $size-small/2;
        margin-bottom: $size-small;


        width: 100%;

        label {
            @extend .btn;
            width: $control-button-width;
            min-width: $control-button-width;
            border-radius: 25px;

            @include colorizeButton($buttonMode: 'normal');

            &.checked {
                @include colorizeButton($buttonMode: 'checked');
            }

            position: absolute;
            left: 0px;

            & + label {
                left: inherit;
                right: 0px;
            }

            padding-left: $size-small/3;
            padding-right: $size-small/3;
        }

        [type="radio"] {
            border: 0;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }
    }

    .success {
        &.feedback-shown {
            max-height: 200px;
        }

        max-height: 0;
        overflow: hidden;

        transition: max-height ease-in;
        transition-duration: .2s;
    }

    .comments {
        &.feedback-shown {
            max-height: 240px;
            .feedback-error{
                margin-top: 5.5px;
            }
        }

        &.complete {
            margin-top: 0;
        }

        margin-top: $input-height-base + $size-medium;
        margin-bottom: -10px;
        max-height: 0;
        overflow: hidden;

        transition: max-height ease-in;
        transition-duration: .2s;

        textarea {
            display: block;
            margin-bottom: $size-small / 2;
            padding: 2px 5px;

            font-size: $font-size-base;
        }

        button {
            margin-right: $size-small;

            &.cancel {
                vertical-align: text-top;

                color: $black;

                &:hover, &:focus {
                    color: $link-hover-color;
                }
            }
        }
    }
    
}

.product-reqs {
    margin-top: 30px;
}

.product-req-title {
    font-weight: bold;
    margin-bottom: 0px;
}

.community-link {
    margin-top: $size-medium + 10px;

    .prompt {
        font-size: 12px;
    }

    a.btn.community-btn {
        width: 100%;
        margin-top: 15px;
    }
}
